<template>
  <section class="pagosPendientes mt-4 ml-4" v-loading="loading">
     <div class="row mb-4">
        <div class="col-12">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                 <el-breadcrumb-item  :to="{ name: 'viviendas.sistema' }">Viviendas</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'viviendas.ver.pagos',params:{id_vivienda: pago.id_vivienda} }"> {{ pago.agrupacion.nombre }} - {{ pago.vivienda.nombre }} </el-breadcrumb-item>
                <el-breadcrumb-item>Pago sin verificar</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="col-12 mt-3">
            <p class="f-20 f-500"> 
              <span v-if="!_.isUndefined(pago.agrupacion)"> {{pago.agrupacion.nombre}} </span> 
              <span v-if="!_.isUndefined(pago.vivienda)" > {{pago.vivienda.nombre}} </span> 
            </p>
        </div>
    </div>
    
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 col-xl-5">
      <!--info-->
        <div class="row">
            <div class="col-12">
                <p class="f-17 f-500 mb-2 d-middle">
                    <img v-if="[31,32,33].includes(pago.estado)" src="/img/generales/pago_online.svg" alt="" class="pr-2">
                    <img v-else src="/img/generales/pago_efectivo.svg" alt="" class="pr-2">

                    <span> {{ convertMoney(debe) }} </span> 
                </p>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-0 mb-lg-2">
                <p class="f-600 f-15">Administración </p>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-0 mb-lg-2">
                <p v-for="(mes,index) in pago.meses" :key="index" class="f-100 f-15"> {{ _.startCase(formatearFecha(mes.fecha,'MMMM YYYY')) }} </p>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-0 mb-lg-2">
                <p class="f-600 f-15">Fecha y hora de registro </p>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-0 mb-lg-2">
                <p class="f-100 f-15"> {{  pago.created_at | helper-fecha('DD/MM/YYYY H:mm a' ) }} </p>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-0 mb-lg-2">
                <p class="f-600 f-15">Medio de pago</p>
            </div>
            <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-0 mb-lg-2">
                <p v-if="[31,32,33].includes(pago.estado)">Pago en línea</p>
                <p v-else-if="pago.estado == 12">Paz y salvo</p>
                <p v-else>Efectivo</p>
            </div>
        </div>
        <!--botones-->
        <div class="row mt-3">
            <div class="col-auto">
                <button type="button" class="btn btn-cerrar f-14 br-4 border px-4 f-300" @click="rechazarPendiente"> Rechazar </button>
            </div>
            <div class="col-auto">
                <button type="button" class="btn btn-general f-14 br-4 border px-4 f-300" @click="aprobarPendiente"> Aprobar </button>
            </div>
        </div>
      </div>
      <div class="col-12 col-md-5 col-lg-auto mt-3 mt-md-0">
        <div v-if="pago.adjunto" class="border br-4 card-img border p-3">
          <img :src="pago.adjunto" style="max-width:700px;max-height:1000px;" alt="" class="w-100 shadow h-100">
        </div>
      </div>
    </div>
    <!-- Partials -->
    <modal-registrar-pago ref="abrirModalRegistrarPago" />
    <modal-confirmar ref="abrirAprobarPendiente" titulo="Aprobar pago" mensaje="¿Desea aprobar el pago?" adicional="Aprobar" @adicional="aprobarPendienteFun" />
    <modal-confirmar ref="abrirRechazarPendiente" titulo="Rechazar pago" mensaje="¿Desea rechazar el pago?" adicional="Rechazar" @adicional="rechazarPendienteFun" />
  </section>
</template>

<script>
import Viviendas from '~/services/viviendas'
export default {
  components:{
    modalRegistrarPago: () => import('./partials/modalRegistrarPago')
  },
    data(){
      return{
        inputSearch:'',
        pago: {},
        loading: false
      }
    },
    computed: {
      debe(){
        let valorMapped = this.pago.meses.map(el=>{return el.valor})
        return _.sum(valorMapped)
      }
    },
    created(){
      this.obtenerPago()
    },
    methods:{
      aprobarPendiente(){
        this.$refs.abrirAprobarPendiente.toggle()
      },
      rechazarPendiente(){
        this.$refs.abrirRechazarPendiente.toggle()
      },
      async aprobarPendienteFun(){
        try {
          this.loading = true
          const {data} = await Viviendas.aceptarPago(this.$route.params.id_pago)
          if(data.success){
            this.$router.replace({ name: 'viviendas.ver', params:{id_vivienda: this.pago.id_vivienda} })
            this.$notify({
              title: 'Aprobar Pago',
              message: data.mensaje,
              type: 'success'
            });
          }
          this.loading = false
        } catch (e){
            console.log(e)
            this.loading = false
            this.$notify({
              title: 'Aprobar Pago',
              message: 'Error aprobando el pago',
              type: 'warning'
            });
        }
      },
      async rechazarPendienteFun(){
        try {
          this.loading = true
          const {data} = await Viviendas.rechazarPago(this.$route.params.id_pago)
          if(data.success){
            this.$router.replace({ name: 'viviendas.ver', params:{id_vivienda: this.pago.id_vivienda} })
            this.$notify({
              title: 'Rechazar Pago',
              message: data.mensaje,
              type: 'success'
            });
          }
          this.loading = false
        } catch (e){
            console.log(e)
            this.loading = false
            this.$notify({
              title: 'Rechazar Pago',
              message: 'Error rechazando el pago',
              type: 'warning'
            });
        }
      },
      async obtenerPago(){
        try {
          this.loading = true
          const {data} = await Viviendas.mostrarPago(this.$route.params.id_pago)
          this.pago = data.data
          this.loading = false
        } catch (e){
            this.errorCatch(e)
            this.loading = false
        }          
      },

    }
}
</script>

<style lang="scss" scoped>
.pagosPendientes{
  .card-img{
    width: 310px;
    height: 310px;
  }
  .btn{
      &-cerrar{
          width: 130px;
      }
      &-general{
          width: 130px;
      }
  }
}
</style>